.container {
    margin: 24px 0px;
    position: relative;
    display: grid;
    column-gap: 16px;
}

.container div > div[class*='DrawerCart_drawer-wrapper__'] {
    min-width: 28vw !important;
    height: 79% !important;
}

.container div > div[class*='DrawerCart_is-expanded__'] {
    width: 36vw !important;
}

.box {
    display: grid;
    grid-row: 1;
    row-gap: 8px;
}

/* vendor and carousel top */
.box-vendor-top {
    grid-column: 1 / 3;
    grid-row: 1;
    display: grid;
    /* grid-template-columns: 412px 1fr; */
    column-gap: 16px;
    position: relative;
}

/* carousel top */
.box-carousel-top {
    display: grid;
    /* grid-column: 2 / 3; */
    position: relative;
}

/* carousel bottom */
.box-carousel-bottom {
    grid-column: 1 / 3;
    grid-row: 2;
    position: relative;
}

/* cart */
.box-cart {
    grid-column: 2 / 3;
    grid-row: 1;
}

.backgroundDMColumnOff {
    background-color: var(--Primary-25, #dcedff);
    /* width: 39px; */
    width: 49px;
    height: 100%;
    position: absolute;
    top: 56px;
    left: 72px;
}

.backgroundDMColumnOn {
    background-color: var(--Primary-25, #dcedff);
    /* width: 436px; */
    width: 437px;
    height: 100%;
    position: absolute;
    top: 56px;
    left: 72px;
    padding: 16px 16px 16px 49px;
}

.grid-container {
    display: grid;
    align-items: flex-start;
    /* gap: 20px; */
    /* margin: 20px 0px; */
    position: relative;
    max-width: 89vw;
}

.left-column {
    position: relative;
    margin-top: -5px;
    gap: 10px;
    display: flex;
    flex-direction: column;
    /* height: calc(100vh - 56px); */
    background-color: var(--Primary-25, #dcedff);

    position: sticky;
    left: 0;
    top: 0;
    /* padding: 16px 8px 16px 49px; */
    padding: 16px 8px 50px 49px;
}

.middle-column {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding-top: 24px;
}

.middle-column .row-1,
.middle-column .row-2 {
    position: relative;
    /* padding-bottom: 20px; */
}

.right-column {
    position: relative;
    transition: all 0.3s ease;
    padding-top: 24px;
}

.arrow {
    position: absolute;
    /* left: 7px; */
    left: 12px;
    top: 15px;
    z-index: 1;
    display: flex;
    width: 24px;
    height: 24px;
    padding: 8px;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    color: white;
    background: var(--Primary-600, #0171e6);
}

.container-arrow {
    display: flex;
    align-items: center;
}

.text-column-left {
    color: var(--Primary-600, #0171e6);
    font-feature-settings: 'liga' off, 'clig' off;

    font-family: 'Source Sans Pro';
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 120% */
    /* flex-flow: wrap; */
}
